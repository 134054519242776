export default [
  {
    title: 'Dashboard',
    route: 'dashboard-eplan',
    icon: 'LayoutIcon',
    resource: 'app',
    action: 'access',
  },
  {
    header: 'Modules',
    resource: 'app',
    action: 'access',
  },
  {
    title: 'Documents',
    // tag: '3',
    // tagVariant: 'light-danger',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        resource: 'document',
        action: 'view',
        route: 'document-list',
      },
      {
        title: 'Upload',
        resource: 'document',
        action: 'add',
        route: { name: 'document-add' },
      },
    ],
  },
  {
    title: 'Reports',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Project Types',
        route: 'report-project-type',
        resource: 'report',
        action: 'view',
      },
      {
        title: 'Type of Plan',
        route: 'report-plan-type',
        resource: 'report',
        action: 'view',
      },
      {
        title: 'Plan Owners',
        route: 'report-plan-owner',
        resource: 'report',
        action: 'view',
      },
      /*
      {
        title: 'Statistics',
        route: 'charts-echart',
        resource: 'report',
        action: 'view',
      },
      */
    ],
  },
  {
    header: 'Administration',
    resource: 'admin',
    action: 'access',
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'user-list',
    resource: 'user',
    action: 'manage',
  },
  {
    title: 'Audit Logs',
    icon: 'ClockIcon',
    route: 'audit-list',
    resource: 'audit',
    action: 'view',
  },
  {
    header: 'Others',
    resource: 'app',
    action: 'access',
  },
  {
    title: 'Home',
    href: '/home.html',
    target: '_self',
    icon: 'HomeIcon',
    resource: 'app',
    action: 'access',
  },
  {
    title: 'Logout',
    route: 'auth-logout',
    icon: 'LogOutIcon',
    resource: 'app',
    action: 'access',
  },
]
