<template>
  <div>
    <b-nav-item
      id="toggle-dark"
      @click="skin = isDark ? 'light' : 'dark'"
    >
      <feather-icon
        size="21"
        :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
      />
    </b-nav-item>
    <b-tooltip
      target="toggle-dark"
      placement="top"
    >
      Toggle light/dark mode
    </b-tooltip>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BNavItem, BTooltip,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
