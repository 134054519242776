<template>
  <b-nav-item-dropdown
    ref="notificationDropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.length }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-dropdown-item
        v-for="notification in notifications"
        :key="notification.id"
        class="notification-item"
        @click="onClick(notification)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.message }}</small>
        </b-media>
      </b-dropdown-item>

      <!-- System Notification Toggler -->
      <!-- div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div -->

      <!-- System Notifications -->
      <!-- b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer p-1">
      <div class="d-flex justify-content-between p-0 m-0">
        <b-dropdown-item-button
          v-if="notifications.length > 0"
          variant="primary"
          @click="onMarkAllRead"
        ><feather-icon
          icon="BellOffIcon"
          class="mr-50"
        />Mark all read</b-dropdown-item-button>
        <b-dropdown-item-button
          variant="primary"
          @click="$router.push({name: 'notifications'})"
        >
          <feather-icon
            icon="ListIcon"
            class="mr-50"
          />View all notifications
        </b-dropdown-item-button>
      </div>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BAvatar, BDropdownItemButton, BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BAvatar,
    VuePerfectScrollbar,
    BDropdownItemButton,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  setup(props, context) {
    const toast = useToast()
    const notification = context.root.$notification
    const router = context.root.$router
    const route = context.root.$route

    /* eslint-disable global-require */
    /*
    {
        title: 'Congratulation Sam 🎉',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success',
      }
     */
    const notifications = ref([])
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // computed

    // methods
    const getNotifications = () => {
      const currentNotificationIds = notifications.value.map(n => n.id)
      store
        .dispatch('auth/getNotifications', { all: 1, unread: 1 })
        .then(response => {
          notifications.value = response.data.payload.map(n => ({
            id: n.id,
            title: n.data.title,
            avatar: n.data.image,
            message: n.data.message,
            url: n.data.url,
            type: 'light-success',
          }))

          // push to browser notification
          const newNotifications = notifications.value.filter(n => n.id.includes(currentNotificationIds) === false)
          newNotifications.forEach(n => {
            console.log('sending new notification')
            notification.show('New Document Review', {
              body: n.message,
              // icon: 'this.appLogo',
            }, {})
          })
        })
        .catch(error => {
          console.log(error)
          // toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Error getting notifications',
          //     text: error.response.data.message,
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // })
        })
    }

    // eslint-disable-next-line no-shadow
    const onClick = notification => {
      console.log(`clicked: ${notification.id}`)
      // mark as read and redirect
      store
        .dispatch('auth/markNotificationsRead', { ids: [notification.id] })
        .then(() => {
          // router.push(n.url.replace(window.location.origin, ''))
          window.location.href = notification.url
        })
    }

    const onMarkAllRead = () => {
      // mark all as read and redirect
      const ids = notifications.value.map(n => n.id)
      store
        .dispatch('auth/markNotificationsRead', { ids })
        .then(() => {
          getNotifications()
        })
    }

    // continuously poll
    const pollNotifications = () => {
      getNotifications()
      setTimeout(pollNotifications, 15 * 1000)
    }
    pollNotifications()

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,

      onClick,
      onMarkAllRead,
    }
  },
}
</script>

<style lang="scss" scoped>
.notification-item {
  cursor: pointer;
}
.dropdown-notification {
  //width: 100%;
  //min-width: 0;
}
.dropdown-notification * {
  white-space: normal;
}

a.dropdown-item {
  padding: 0;
}
</style>
